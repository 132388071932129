import * as React from 'react'
import Layout from '../components/layout'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFilePdf } from '@fortawesome/free-solid-svg-icons'
import { StaticImage } from "gatsby-plugin-image"
import svg_1 from '../images/undraw_graduation_re_gthn.svg'
//index.js file

const IndexPage = () => {
  return (
    <Layout pageTitle="Hem">

      <div className='mt-5 mb-10 p-4 w-full'>
        <img className='w-full' src={svg_1} alt="ovve" placeholder='blurred'></img>
      </div>

      <div className='p-4 shadow-md w-full'>
        <div className='text-lg font-bold'>Märken till ovve</div>
        <ul>
          <li className='py-3 underline'><a href="https://www.markbar.se/">Märkbar</a></li>
          <li className='py-3 underline'><a href="https://minovve.se/marken/">Min ovve</a></li>
        </ul>
      </div>

      <div className='mt-5 p-4 shadow-md w-full'>
        <div className='text-lg font-bold'>Länkar</div>
        <ul>
          <li className='py-3 underline'><a href="https://www.hh.se/">Högskolan i Halmstad</a></li>
          <li className='py-3 underline'><a href="https://bb.hh.se/ultra/course">Blackboard</a></li>
        </ul>
      </div>

      <div className='mt-5 p-4 shadow-md w-full'>
        <div className='text-lg font-bold'>Filer</div>
        <ul>
          <li className='py-3'>
            <a className='bg-gray-200 p-2 hover:bg-gray-100' href="/files/Nolleskriphten_2022.pdf" target="_blank">
              <FontAwesomeIcon className='text-red-800' icon={faFilePdf}/> Nolleskriphten 2022
            </a>
          </li>
        </ul>
      </div>
    </Layout>
  )
}

export default IndexPage